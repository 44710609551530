<template>
  <div class="w-full">
    <div class="mb-14 mt-6">
      <h2 class="text-center text-2xl tracking-tighter leading-9 font-extrabold mb-2">
        {{ $t('pages.auth.index.find.index.email.title') }}
      </h2>
      <p class="text-sm mt-2 opacity-75 text-center leading-5">
        {{ $t('pages.auth.index.find.index.email.info') }}
      </p>
    </div>

    <input
      v-model="email"
      :aria-label="$t('auth.email')"
      :placeholder="$t('auth.email')"
      name="email"
      type="email"
      required
      class="field appearance-none relative block text-base w-full px-3 py-2 border border-gray-200 dark:border-gray-800 bg-white/20 dark:bg-gray-900 rounded-lg placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:leading-5"
      @keydown.enter="handleKeyDown"
    >

    <div class="mt-8">
      <nuxt-link to="/auth/find/code" disabled class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
        {{ $t('auth.continue') }}
      </nuxt-link>
    </div>

    <div class="text-sm mt-6 opacity-75 text-center">
      <i18n path="pages.auth.index.find.index.email.ifOldOvatu" tag="p">
        <template #loginHere>
          <a href="https://ovatu.com/location" class="underline">{{ $t('pages.auth.index.find.index.email.loginHere') }}</a>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'auth',
  computed: {
    email: {
      get () {
        return this.$store.state.auth.auth.email
      },
      set (value) {
        this.$store.commit('auth/set', { key: 'email', value })
      },
    },
  },
  methods: {
    handleKeyDown () {
      if (this.email) {
        this.$router.push('/auth/find/code')
      }
    },
  },
}
</script>
